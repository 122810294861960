<template>
    <div class="d-flex flex-column login">
        <h4 v-if="!name">Log in to continue</h4>
        <div v-if="name" class="another-account-name">
            <h4>Hi {{ name }}, looks like you've been logged out</h4>

            <div>
                <div>Not {{ name }}?</div>
                <a href="" v-on:click.prevent="onAnotherAccountHandler($event)">Use another account</a>
            </div>
        </div>

        <div class="another-account" v-if="!name">
            <div>{{ email }}</div>
            <a href="" v-on:click.prevent="onAnotherAccountHandler($event)">Use another account</a>
        </div>

        <form @submit.prevent="handleFormSubmit" class="login-form d-flex flex-column" novalidate>
            <div class="form-item d-flex flex-column">
                <label for="password">Password</label>
                <Input
                    :value="password"
                    id="password"
                    name="password"
                    type="password"
                    placeholder=""
                    :disabled="loading"
                    @on-input="handleInput"
                    :invalid="!!error"
                />
                <p v-if="!!error" class="error">{{ error }}</p>
            </div>
            <div class="d-flex actions flex-column flex-md-row">
                <button type="submit" class="btn btn-dark" :disabled="loading">Continue</button>
            </div>
            <a href="/forgot-password">Forgot password?</a>
        </form>
    </div>
</template>
<script>
import Input from '../../atoms/PrivateInput.vue';
import axios from 'axios';
import AlertMessage from '../../molecules/AlertMessage.vue';

export default {
    props: {
        email: String,
        name: String,
    },
    components: {
        AlertMessage,
        Input,
    },
    data() {
        return {
            loading: false,
            error: '',
            password: '',
        };
    },
    methods: {
        handleInput(value) {
            this.password = value;
            this.error = null;
        },
        onAnotherAccountHandler() {
            this.$emit('on-another-account');
        },
        async handleFormSubmit() {
            this.error = null;

            this.loading = true;
            const response = await axios
                .post(
                    this.route('internal-api.auth.login'),
                    { email: this.email, password: this.password },
                    { withCredentials: true }
                )
                .catch(() => null);

            if (response?.data?.two_factor_authentication_enabled) {
                this.$emit('on-two-factor-auth-enabled', this.email, this.password);
            } else if (response) {
                window.location.href = response.data.data.attributes.redirect_url;
            } else {
                this.loading = false;
                this.error = 'Your credentials appear to be incorrect, please check your details and try again.';
            }
        },
    },
};
</script>
