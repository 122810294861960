import Flash from '../utilities/Flash';
import repository from './repositories/BaseRepository';
import moment from 'moment';
import formatCurrency from './../utilities/formatCurrency';
import { ucFirst as ucFirstHelper } from '../helpers/String';
import axios from 'axios';

const formatTitleCase = (str, split = '_') => {
    if (!str) {
        return '';
    }

    return str
        .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        })
        .split(split)
        .join(' ');
};

export default {
    computed: {
        $repository() {
            return repository(axios);
        },
        authenticatedAgentUuid() {
            return window.document.head.querySelector('meta[name="user-uuid"]')?.getAttribute('content');
        },
    },

    filters: {
        time(value) {
            return moment(value).format('HH:mm');
        },

        date(value, format) {
            return moment(value).format(format || 'DD MMM Y');
        },

        currency(value) {
            return formatCurrency(value);
        },

        ucFirst(value) {
            return ucFirstHelper(value);
        },

        titleCase(value) {
            return formatTitleCase(value);
        },
    },

    methods: {
        moment(date, format) {
            return moment(date, format);
        },
        toast(level, title, content = null, timeout = 1000) {
            window.toastr[level](content, title, timeout);
        },
        formatDate(date, format = 'DD/MM/YYYY') {
            return moment(date).format(format);
        },

        formatTitleCase(str, split = '_') {
            return formatTitleCase(str, split);
        },

        pluralize(count, noun, suffix = 's') {
            return `${noun}${count !== 1 ? suffix : ''}`;
        },

        flash(message, level = 'success') {
            this.$eventBus.$emit('flashMessage', level, message);
        },

        removeFlash(index) {
            this.$eventBus.$emit('removeFlash', index);
        },

        flashLater(message, level = 'success') {
            Flash.push(message, level);
        },
    },
};
