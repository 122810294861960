<template>
    <div class="social-auth-form-container d-flex flex-column">
        <EmailForm v-if="state === 'email'" @on-email-continue="handleEmailContinue" />
        <CodeForm
            v-if="state === 'code'"
            :email="email"
            @on-code-continue="handleCodeContinue"
            @on-code-back="handleCodeBack"
        />
        <PasswordForm v-if="state === 'password'" :email="email" :code="code" />
    </div>
</template>
<script>
import EmailForm from '../organisms/reset/ResetEmailForm';
import CodeForm from '../organisms/reset/ResetCodeForm';
import PasswordForm from '../organisms/reset/ResetPasswordForm';
export default {
    components: {
        EmailForm,
        CodeForm,
        PasswordForm,
    },
    data() {
        return {
            error: null,
            loading: false,
            state: 'email',
            email: '',
            code: '',
        };
    },
    methods: {
        handleEmailContinue(value) {
            this.email = value;
            this.state = 'code';
        },
        handleCodeContinue(value) {
            this.code = value;
            this.state = 'password';
        },
        handleCodeBack() {
            this.state = 'email';
        },
    },
};
</script>
