var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _vm.label
      ? _c("label", { attrs: { for: _vm.name } }, [_vm._v(_vm._s(_vm.label))])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "position-relative" },
      [
        _c(
          "select",
          {
            staticClass: "form-control custom-select",
            class: {
              "w-auto": _vm.notFullWidth,
              "border-danger": _vm.hasError
            },
            attrs: {
              name: _vm.name,
              id: _vm.name,
              "data-testid": _vm.testId,
              disabled: _vm.disabled
            },
            on: { change: _vm.onSelect }
          },
          [
            !_vm.withoutPlaceholder
              ? _c(
                  "option",
                  {
                    attrs: { value: "", disabled: _vm.placeholderDisabled },
                    domProps: { selected: _vm.isDefault() }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.placeholder) +
                        "\n            "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.options, function(label, key) {
              return _c(
                "option",
                {
                  key: key,
                  domProps: { value: key, selected: _vm.isSelected(key) }
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(label) + "\n            "
                  )
                ]
              )
            }),
            _vm._v(" "),
            _vm._l(_vm.groups, function(items, label) {
              return _c(
                "optgroup",
                { key: label, attrs: { label: label } },
                _vm._l(items, function(label, key) {
                  return _c(
                    "option",
                    {
                      key: key,
                      domProps: { value: key, selected: _vm.isSelected(key) }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(label) +
                          "\n                "
                      )
                    ]
                  )
                }),
                0
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _vm.loading
          ? _c("SLoadingSkeleton", { attrs: { "auto-size": "" } })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }