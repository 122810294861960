<template>
    <div class="d-flex flex-column login">
        <h4>First, what’s your email address?</h4>

        <form class="login-form d-flex flex-column" novalidate @submit.prevent>
            <div class="form-item d-flex flex-column">
                <label for="email">Email address</label>
                <EmailField
                    :value="email"
                    id="email"
                    name="email"
                    type="text"
                    placeholder="Email Address"
                    @on-input="handleInput"
                />
                <p v-if="!!error" class="error">{{ error }}</p>
            </div>
            <div class="d-flex actions flex-column flex-md-row">
                <button v-on:click="handleFormSubmit()" :disabled="loading" class="btn btn-dark">Continue</button>
            </div>
            <a href="/login" class="login-tag">Log in</a>
        </form>
    </div>
</template>
<script>
import EmailField from '../../atoms/Input.vue';
import axios from 'axios';
export default {
    components: {
        EmailField,
    },
    data() {
        return {
            email: '',
            error: '',
            loading: false,
        };
    },
    methods: {
        handleInput(value) {
            this.error = '';
            this.email = value;
        },
        async handleFormSubmit() {
            this.error = null;

            this.loading = true;
            const response = await axios
                .post(this.route('internal-api.auth.send-reset-code'), {
                    email: this.email,
                })
                .catch(() => null);
            if (response) {
                this.$emit('on-email-continue', this.email);
            } else {
                this.error = 'This email could not be found, please enter the one you gave the agent.';
            }
            this.loading = false;
        },
    },
};
</script>
