var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "social-auth-form-container d-flex flex-column" },
    [
      _vm.state === "email"
        ? _c("EmailForm", {
            attrs: { provider: _vm.provider, identifier: _vm.identifier },
            on: { "on-email-continue": _vm.handleEmailContinue }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.state === "code"
        ? _c("CodeForm", {
            attrs: {
              provider: _vm.provider,
              "social-id": _vm.socialId,
              email: _vm.email
            },
            on: { "on-another-account": _vm.handleAnotherAccount }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("CantLogin")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }