var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column login" },
    [
      _c("h4", [_vm._v("Log in to continue")]),
      _vm._v(" "),
      _vm.showLoginLinkSuccess
        ? _c("alert-message", {
            attrs: { message: "Your login link is on the way" }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showResetPasswordSuccess
        ? _c("alert-message", {
            attrs: {
              message: "Your password has been reset, you can now log in"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSetPasswordSuccess
        ? _c("alert-message", {
            attrs: { message: "Your password has been set, you can now log in" }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "login-form d-flex flex-column",
          attrs: { novalidate: "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-item d-flex flex-column" },
            [
              _c("label", { attrs: { for: "email" } }, [_vm._v("Email")]),
              _vm._v(" "),
              _c("EmailField", {
                attrs: {
                  value: _vm.email,
                  id: "email",
                  name: "email",
                  type: "email",
                  placeholder: "Email Address"
                },
                on: { "on-input": _vm.handleInput }
              }),
              _vm._v(" "),
              !!_vm.error
                ? _c("p", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex actions flex-column flex-md-row" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-dark",
                attrs: { disabled: _vm.loading },
                on: {
                  click: function($event) {
                    return _vm.handleFormSubmit()
                  }
                }
              },
              [_vm._v("Continue")]
            ),
            _vm._v(" "),
            _vm.showPasswordless
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-light",
                    attrs: { type: "button", disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.emailLoginLink()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                Email me a login link\n            "
                    )
                  ]
                )
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }