<template>
    <div class="d-flex flex-column login two-factor-auth">
        <h4>Log in to continue</h4>

        <p>
            <small class="form-text text-muted">
                Please enter the code from your authentication app <em>(eg. Google Authenticator)</em> on your mobile
                device.
            </small>
        </p>

        <form class="login-form d-flex flex-column" novalidate @submit.prevent>
            <div class="form-item d-flex flex-column">
                <label for="email">Code</label>
                <input v-model="code" id="code" name="code" type="text" placeholder="Enter your authentication code" />
                <p v-if="!!error" class="error">{{ error }}</p>
            </div>

            <div class="d-flex actions flex-column flex-md-row">
                <button v-on:click="handleFormSubmit()" :disabled="loading" class="btn btn-dark">Sign In</button>
            </div>
        </form>

        <p class="recovery">
            Having trouble getting the code?
            <span v-on:click="handleShowRecoveryCodeForm()">Log in using recovery code</span>
        </p>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    props: {
        email: String,
        password: String,
    },
    data() {
        return {
            code: '',
            error: '',
            valid: false,
            loading: false,
        };
    },
    methods: {
        async handleFormSubmit() {
            this.error = null;

            this.loading = true;

            try {
                const response = await axios.post(
                    this.route('internal-api.auth.2fa.login'),
                    {
                        code: this.code,
                        email: this.email,
                        password: this.password,
                    },
                    { withCredentials: true }
                );

                window.location.href = response.data?.data?.attributes.redirect_url;
            } catch (e) {
                if (e.response?.status === 422) {
                    this.loading = false;
                    this.error = 'Invalid code, please check your details and try again.';
                } else {
                    throw e;
                }
            }
        },

        handleShowRecoveryCodeForm() {
            this.$emit('on-show-recover-code-form', this.email, this.password);
        },
    },
};
</script>
