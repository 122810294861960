<template>
    <div class="s-loading-placeholder rounded" :class="classes" :style="style" />
</template>

<script>
export default {
    props: {
        variant: {
            type: String,
            default: 'grey-200',
        },

        height: {
            type: String,
            required: false,
        },

        width: {
            type: String,
            default: '100%',
        },

        // When using autosize the parent must be set to position-relative
        autoSize: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classes() {
            return [`bg-${this.variant}`, ...(this.autoSize ? ['position-pin'] : [])];
        },
        style() {
            return this.autoSize ? {} : { height: this.height, width: this.width };
        },
    },
};
</script>

<style scoped lang="scss">
.s-loading-placeholder {
    animation: loadingShimmer 1.5s linear infinite forwards;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 25%, rgba(0, 0, 0, 0) 50%);
    background-size: 1000px 500px;
}

@keyframes loadingShimmer {
    0% {
        background-position: -500px 0;
    }

    100% {
        background-position: 500px 0;
    }
}
</style>
