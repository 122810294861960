var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal.modal-vue",
              modifiers: { "modal-vue": true }
            }
          ],
          staticClass: "text-decoration-underline"
        },
        [_vm._v(_vm._s(_vm.text))]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-vue",
            "ok-only": _vm.showOnlyAccept,
            "ok-title": _vm.okTitle
          }
        },
        [
          _c("div", { attrs: { slot: "modal-header" }, slot: "modal-header" }, [
            _c("h4", [_vm._v(_vm._s(_vm.title))])
          ]),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }