var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column link" }, [
    _c("div", { staticClass: "social-account" }, [
      _c("h5", [_vm._v("You’re trying to log in with")]),
      _vm._v(" "),
      _c(
        "p",
        [
          _vm.provider === "google"
            ? _c("GoogleIcon", { staticClass: "icon" })
            : _vm._e(),
          _vm._v(" "),
          _vm.provider === "apple"
            ? _c("AppleIcon", { staticClass: "icon" })
            : _vm._e(),
          _vm._v(" "),
          _vm.provider === "facebook"
            ? _c("FacebookIcon", { staticClass: "icon" })
            : _vm._e(),
          _vm._v(" "),
          _vm.provider === "microsoft"
            ? _c("WindowsIcon", { staticClass: "icon" })
            : _vm._e(),
          _vm._v("\n            " + _vm._s(_vm.identifier) + "\n        ")
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("h4", [_vm._v("Hmm, looks like you don’t have an account")]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "\n        Not to worry - we’ll just need the email address that you gave to your estate agent - then we’ll send you a\n        code to verify it’s you\n    "
      )
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "login-form d-flex flex-column",
        attrs: { novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "form-item d-flex flex-column" },
          [
            _c("label", { attrs: { for: "email" } }, [_vm._v("Email address")]),
            _vm._v(" "),
            _c("Input", {
              attrs: {
                value: _vm.email,
                id: "email",
                name: "email",
                type: "text",
                placeholder: "Email Address",
                disabled: _vm.loading,
                invalid: !!_vm.error
              },
              on: { "on-input": _vm.handleInput }
            }),
            _vm._v(" "),
            !!_vm.error
              ? _c("p", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex actions flex-column flex-md-row" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-dark",
              attrs: { disabled: _vm.loading },
              on: {
                click: function($event) {
                  return _vm.handleFormSubmit()
                }
              }
            },
            [_vm._v("Continue")]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }