<template>
    <div class="d-flex flex-column login">
        <h4>Log in to continue</h4>

        <alert-message v-if="showLoginLinkSuccess" message="Your login link is on the way"></alert-message>

        <alert-message
            v-if="showResetPasswordSuccess"
            message="Your password has been reset, you can now log in"
        ></alert-message>

        <alert-message
            v-if="showSetPasswordSuccess"
            message="Your password has been set, you can now log in"
        ></alert-message>

        <form class="login-form d-flex flex-column" novalidate @submit.prevent>
            <div class="form-item d-flex flex-column">
                <label for="email">Email</label>
                <EmailField
                    :value="email"
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email Address"
                    @on-input="handleInput"
                />
                <p v-if="!!error" class="error">{{ error }}</p>
            </div>
            <div class="d-flex actions flex-column flex-md-row">
                <button v-on:click="handleFormSubmit()" :disabled="loading" class="btn btn-dark">Continue</button>
                <button
                    v-if="showPasswordless"
                    v-on:click="emailLoginLink()"
                    type="button"
                    :disabled="loading"
                    class="btn btn-light"
                >
                    Email me a login link
                </button>
            </div>
        </form>
    </div>
</template>
<script>
import EmailField from '../../atoms/Input.vue';
import AlertMessage from '../../molecules/AlertMessage.vue';
import axios from 'axios';
export default {
    components: {
        EmailField,
        AlertMessage,
    },
    props: {
        showPasswordless: Boolean,
    },
    data() {
        return {
            email: '',
            error: '',
            valid: false,
            showLoginLinkSuccess: false,
            showResetPasswordSuccess: false,
            showSetPasswordSuccess: false,
            loading: false,
        };
    },
    created() {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);

        this.showResetPasswordSuccess = params.get('reset') === 'true';
        this.showSetPasswordSuccess = params.get('setPassword') === 'true';
    },
    methods: {
        handleInput(value) {
            this.error = '';
            this.email = value;
            this.valid = value.length;
            this.showResetPasswordSuccess = false;
            this.showSetPasswordSuccess = false;
        },
        async emailLoginLink() {
            this.error = null;

            if (!this.valid) {
                this.error = 'Please enter your email';
                return;
            }

            this.loading = true;
            await axios
                .post(this.route('internal-api.auth.passwordless-login'), {
                    email: this.email,
                })
                .then(() => {
                    this.showLoginLinkSuccess = true;
                    setTimeout(() => (this.showLoginLinkSuccess = false), 30000);
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        this.error = 'Please enter a valid email address';
                    } else if (e.response.status === 401) {
                        this.error =
                            'This account does not support this request. Please login with your email address and password.';
                    } else {
                        this.error = 'We are currently facing difficulties with this request please try again later.';
                    }

                    return;
                });

            this.loading = false;
        },
        async handleFormSubmit() {
            if (!this.valid) {
                this.error = 'Please enter your email';
                return;
            }
            this.$emit('on-email-continue', this.email);
        },
    },
};
</script>
