<template>
    <div class="d-flex flex-column login">
        <div class="title-description">
            <h4>Great, time to set your new password</h4>
            <p>It’ll need to be at least 8 characters</p>
        </div>

        <form @submit.prevent="handleFormSubmit" autocomplete="off" novalidate class="login-form d-flex flex-column">
            <div class="form-item d-flex flex-column">
                <label for="password-input">Enter password</label>
                <Input
                    :value="password"
                    id="password-input"
                    name="password-input"
                    type="password"
                    placeholder=""
                    ref="passwordInput"
                    :disabled="loading"
                    @on-input="handleInput"
                    :invalid="!!error"
                />
            </div>
            <div class="form-item d-flex flex-column">
                <label for="password-input2">Re-enter password</label>
                <Input
                    :value="passwordConfirmation"
                    id="password-input2"
                    name="password-input2"
                    type="password"
                    placeholder=""
                    ref="passwordInput2"
                    :disabled="loading"
                    @on-input="handleInput2"
                    :invalid="!!error"
                />
                <p v-if="!!error" class="error">{{ error }}</p>
            </div>
            <div class="d-flex actions flex-column flex-md-row">
                <button type="submit" class="btn btn-dark" :disabled="loading">Continue</button>
            </div>
            <a href="/login" class="login-tag">Log in</a>
        </form>
    </div>
</template>
<script>
import Input from '../../atoms/PrivateInput.vue';
import axios from 'axios';
import AlertMessage from '../../molecules/AlertMessage';
export default {
    props: {
        email: String,
        code: String,
    },
    components: {
        Input,
        AlertMessage,
    },
    data() {
        return {
            loading: false,
            error: '',
            password: '',
            passwordConfirmation: '',
        };
    },
    methods: {
        handleInput(value) {
            this.password = value;
            this.error = null;
        },
        handleInput2(value) {
            this.passwordConfirmation = value;
            this.error = null;
        },
        async handleFormSubmit() {
            this.error = null;

            if (this.password.length < 8 || this.passwordConfirmation.length < 8) {
                this.error = 'Your password must be a minimum of 8 letters.';
                return;
            }

            if (this.password !== this.passwordConfirmation) {
                this.error = 'Please make sure your passwords match.';
                return;
            }

            this.loading = true;
            const response = await axios
                .post(this.route('internal-api.auth.reset-password'), {
                    email: this.email,
                    code: this.code,
                    password: this.password,
                    password_confirmation: this.passwordConfirmation,
                })
                .catch(() => null);
            if (response) {
                window.location.href = '/login?reset=true';
            } else {
                this.error = 'We are currently facing difficulties with this request please try again later.';
            }
            this.loading = false;
        },
    },
};
</script>
