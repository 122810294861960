var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column link" }, [
    _c("h4", [_vm._v("We’ve sent a code to your email address")]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "If you have a Street account associated with that email address, you’ll get an email from us."
      )
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "\n        We can then link your account so that you can log in using your email and password, or your\n        " +
          _vm._s(_vm.providerName) +
          " account!\n    "
      )
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "login-form d-flex flex-column",
        attrs: { autocomplete: "off", novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.handleFormSubmit($event)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "form-item d-flex flex-column" },
          [
            _c("label", { attrs: { for: "code-input" } }, [_vm._v("Code")]),
            _vm._v(" "),
            _c("Input", {
              ref: "codeInput",
              attrs: {
                value: _vm.code,
                id: "code-input",
                name: "code-input",
                placeholder: "",
                disabled: _vm.loading,
                invalid: !!_vm.error
              },
              on: { "on-input": _vm.handleInput }
            }),
            _vm._v(" "),
            !!_vm.error
              ? _c("p", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex actions flex-column flex-md-row" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-dark",
              attrs: { type: "submit", disabled: _vm.loading }
            },
            [_vm._v("Continue")]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }