var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-group password-container" }, [
    _c("input", {
      ref: "input",
      class: _vm.invalid ? "invalid" : "",
      attrs: {
        id: _vm.id,
        name: _vm.name,
        type: this.visible ? "text" : "password",
        minlength: _vm.minlength,
        placeholder: _vm.placeholder,
        required: _vm.required
      },
      domProps: { value: _vm.value },
      on: { input: _vm.onInput }
    }),
    _vm._v(" "),
    _c("div", { class: _vm.invalid ? "invalid toggle" : "toggle" }, [
      _c(
        "div",
        [
          !_vm.visible
            ? _c("EyeOffIcon", {
                staticClass: "icon",
                on: {
                  click: function($event) {
                    return _vm.handleEyeClick(true)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.visible
            ? _c("EyeOnIcon", {
                staticClass: "icon",
                on: {
                  click: function($event) {
                    return _vm.handleEyeClick(false)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }