var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column login two-factor-auth" },
    [
      _c("h4", [_vm._v("Log in to continue")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "login-form d-flex flex-column",
          attrs: { novalidate: "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("div", { staticClass: "form-item d-flex flex-column" }, [
            _c("label", { attrs: { for: "email" } }, [_vm._v("Code")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.code,
                  expression: "code"
                }
              ],
              attrs: {
                id: "code",
                name: "code",
                type: "text",
                placeholder: "Enter your authentication code"
              },
              domProps: { value: _vm.code },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.code = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            !!_vm.error
              ? _c("p", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex actions flex-column flex-md-row" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-dark",
                attrs: { disabled: _vm.loading },
                on: {
                  click: function($event) {
                    return _vm.handleFormSubmit()
                  }
                }
              },
              [_vm._v("Sign In")]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "recovery" }, [
        _vm._v("\n        Having trouble getting the code?\n        "),
        _c(
          "span",
          {
            on: {
              click: function($event) {
                return _vm.handleShowRecoveryCodeForm()
              }
            }
          },
          [_vm._v("Log in using recovery code")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("small", { staticClass: "form-text text-muted" }, [
        _vm._v(
          "\n            Please enter the code from your authentication app "
        ),
        _c("em", [_vm._v("(eg. Google Authenticator)")]),
        _vm._v(" on your mobile\n            device.\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }