var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "logging-issue text-center" },
    [
      _c(
        "Modal",
        {
          attrs: {
            title: "Can't log in?",
            text: "Can't log in?",
            showOnlyAccept: "",
            okTitle: "Got it!"
          }
        },
        [
          _c("div", { staticClass: "cant-login-modal" }, [
            _c("h5", [_vm._v("Are you a buyer, seller, tenant or landlord?")]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("p", [
                _vm._v(
                  "\n                    If you’re registered with an estate agent who uses Street, you may have received emails from us\n                    asking you to log into your account. You’ll need to use that same email address to log in.\n                "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n                    If you’ve never set or if you’ve forgotten password, you can\n                    "
                ),
                _c("a", { attrs: { href: "/forgot-password" } }, [
                  _vm._v("reset it")
                ]),
                _vm._v(".\n                ")
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "If you’re still struggling to log in, please contact your estate agent for help."
                )
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }