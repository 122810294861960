<template>
    <div class="d-flex flex-column link">
        <div class="social-account">
            <h5>You’re trying to log in with</h5>
            <p>
                <GoogleIcon v-if="provider === 'google'" class="icon" />
                <AppleIcon v-if="provider === 'apple'" class="icon" />
                <FacebookIcon v-if="provider === 'facebook'" class="icon" />
                <WindowsIcon v-if="provider === 'microsoft'" class="icon" />
                {{ identifier }}
            </p>
        </div>

        <h4>Hmm, looks like you don’t have an account</h4>

        <p>
            Not to worry - we’ll just need the email address that you gave to your estate agent - then we’ll send you a
            code to verify it’s you
        </p>

        <form class="login-form d-flex flex-column" novalidate @submit.prevent>
            <div class="form-item d-flex flex-column">
                <label for="email">Email address</label>
                <Input
                    :value="email"
                    id="email"
                    name="email"
                    type="text"
                    placeholder="Email Address"
                    :disabled="loading"
                    @on-input="handleInput"
                    :invalid="!!error"
                />
                <p v-if="!!error" class="error">{{ error }}</p>
            </div>
            <div class="d-flex actions flex-column flex-md-row">
                <button v-on:click="handleFormSubmit()" :disabled="loading" class="btn btn-dark">Continue</button>
            </div>
        </form>
    </div>
</template>
<script>
import Input from '../../atoms/Input.vue';
import AppleIcon from '../../../assets/apple-icon.svg';
import FacebookIcon from '../../../assets/facebook-icon.svg';
import GoogleIcon from '../../../assets/google-icon.svg';
import WindowsIcon from '../../../assets/windows-icon.svg';
import axios from 'axios';
export default {
    components: {
        AppleIcon,
        FacebookIcon,
        GoogleIcon,
        WindowsIcon,
        Input,
    },
    props: {
        provider: String,
        identifier: String,
    },
    data() {
        return {
            email: '',
            error: '',
            loading: false,
        };
    },
    methods: {
        handleInput(value) {
            this.error = null;
            this.email = value;
        },
        async handleFormSubmit() {
            this.error = null;

            this.loading = true;
            const response = await axios
                .post(this.route('internal-api.auth.social.send'), { email: this.email }, { withCredentials: true })
                .catch(e => {
                    const message = e?.response?.data?.message;
                    if (message) {
                        this.error = message;
                    } else {
                        this.error =
                            'There has been a problem sending your code, please check your email and try again.';
                    }
                    return null;
                });

            if (response) {
                this.$emit('on-email-continue', this.email);
            }
            this.loading = false;
        },
    },
};
</script>
