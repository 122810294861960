var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column login" }, [
    !_vm.name ? _c("h4", [_vm._v("Log in to continue")]) : _vm._e(),
    _vm._v(" "),
    _vm.name
      ? _c("div", { staticClass: "another-account-name" }, [
          _c("h4", [
            _vm._v(
              "Hi " + _vm._s(_vm.name) + ", looks like you've been logged out"
            )
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", [_vm._v("Not " + _vm._s(_vm.name) + "?")]),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.onAnotherAccountHandler($event)
                  }
                }
              },
              [_vm._v("Use another account")]
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.name
      ? _c("div", { staticClass: "another-account" }, [
          _c("div", [_vm._v(_vm._s(_vm.email))]),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: { href: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.onAnotherAccountHandler($event)
                }
              }
            },
            [_vm._v("Use another account")]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "login-form d-flex flex-column",
        attrs: { novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.handleFormSubmit($event)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "form-item d-flex flex-column" },
          [
            _c("label", { attrs: { for: "password" } }, [_vm._v("Password")]),
            _vm._v(" "),
            _c("Input", {
              attrs: {
                value: _vm.password,
                id: "password",
                name: "password",
                type: "password",
                placeholder: "",
                disabled: _vm.loading,
                invalid: !!_vm.error
              },
              on: { "on-input": _vm.handleInput }
            }),
            _vm._v(" "),
            !!_vm.error
              ? _c("p", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex actions flex-column flex-md-row" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-dark",
              attrs: { type: "submit", disabled: _vm.loading }
            },
            [_vm._v("Continue")]
          )
        ]),
        _vm._v(" "),
        _c("a", { attrs: { href: "/forgot-password" } }, [
          _vm._v("Forgot password?")
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }