<template>
    <div class="d-flex flex-column link">
        <h4>We’ve sent a code to your email address</h4>
        <p>If you have a Street account associated with that email address, you’ll get an email from us.</p>
        <p>
            We can then link your account so that you can log in using your email and password, or your
            {{ providerName }} account!
        </p>

        <form @submit.prevent="handleFormSubmit" autocomplete="off" class="login-form d-flex flex-column" novalidate>
            <div class="form-item d-flex flex-column">
                <label for="code-input">Code</label>
                <Input
                    :value="code"
                    id="code-input"
                    name="code-input"
                    placeholder=""
                    :disabled="loading"
                    ref="codeInput"
                    @on-input="handleInput"
                    :invalid="!!error"
                />
                <p v-if="!!error" class="error">{{ error }}</p>
            </div>
            <div class="d-flex actions flex-column flex-md-row">
                <button type="submit" class="btn btn-dark" :disabled="loading">Continue</button>
            </div>
        </form>
    </div>
</template>
<script>
import Input from '../../atoms/Input.vue';
import axios from 'axios';
export default {
    props: {
        email: String,
        provider: String,
        socialId: String,
    },
    components: {
        Input,
    },
    data() {
        let name = '';
        switch (this.provider) {
            case 'apple':
                name = 'Apple';
                break;
            case 'facebook':
                name = 'Facebook';
                break;
            case 'google':
                name = 'Google';
                break;
            case 'microsoft':
                name = 'Microsoft';
                break;
        }
        return {
            code: '',
            providerName: name,
            loading: false,
            error: '',
        };
    },
    methods: {
        handleInput(value) {
            this.code = value;
            this.error = null;
        },
        async handleFormSubmit() {
            this.error = null;

            const valid = this.code.length;

            if (!valid) {
                this.error = 'Please enter your code.';
                return;
            }

            this.loading = true;
            const response = await axios
                .post(
                    this.route('internal-api.auth.social.link'),
                    {
                        email: this.email,
                        code: this.code,
                        provider: this.provider,
                        social_id: this.socialId,
                    },
                    { withCredentials: true }
                )
                .catch(() => null);

            if (response) {
                window.location.href = response.data.data.attributes.redirect_url;
            } else {
                this.loading = false;
                this.error = 'Sorry there was a problem linking your account, please check your code and try again.';
            }
        },
    },
};
</script>
