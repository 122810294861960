export const ucFirst = input => input.charAt(0).toUpperCase() + input.slice(1);

export const slugify = text =>
    text
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');

export default {
    ucFirst,
};
