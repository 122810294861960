var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "social-auth-form-container d-flex flex-column" },
    [
      _vm.state === "email"
        ? _c("EmailForm", {
            attrs: { showPasswordless: _vm.showPasswordless },
            on: { "on-email-continue": _vm.handleEmailContinue }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.state === "password"
        ? _c("PasswordForm", {
            attrs: { email: _vm.email, name: _vm.name },
            on: {
              "on-another-account": _vm.handleAnotherAccount,
              "on-two-factor-auth-enabled": _vm.handleTwoFactorAuthEnabled
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.state === "2fa"
        ? _c("TwoFactorAuthenticationForm", {
            attrs: { email: _vm.email, password: _vm.password, name: _vm.name },
            on: { "on-show-recover-code-form": _vm.handleShowRecoverCodeForm }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.state === "2fa-recovery"
        ? _c("TwoFactorAuthenticationRecoveryForm", {
            attrs: { email: _vm.email, password: _vm.password, name: _vm.name },
            on: { "on-two-factor-auth-enabled": _vm.handleTwoFactorAuthEnabled }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSocials
        ? _c("SocialLogin", {
            attrs: { "enabled-socials": _vm.enabledSocials }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showCantLoginModal ? _c("CantLogin") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }