<template>
    <div class="form-group">
        <label v-if="label" :for="name">{{ label }}</label>

        <div class="position-relative">
            <select
                :name="name"
                :id="name"
                :data-testid="testId"
                class="form-control custom-select"
                :class="{ 'w-auto': notFullWidth, 'border-danger': hasError }"
                @change="onSelect"
                :disabled="disabled"
            >
                <option v-if="!withoutPlaceholder" :selected="isDefault()" value="" :disabled="placeholderDisabled">
                    {{ placeholder }}
                </option>

                <option v-for="(label, key) in options" :key="key" :value="key" :selected="isSelected(key)">
                    {{ label }}
                </option>

                <optgroup v-for="(items, label) in groups" :key="label" :label="label">
                    <option v-for="(label, key) in items" :key="key" :value="key" :selected="isSelected(key)">
                        {{ label }}
                    </option>
                </optgroup>
            </select>
            <SLoadingSkeleton v-if="loading" auto-size />
        </div>
    </div>
</template>

<script>
import SLoadingSkeleton from '../suds/SLoadingSkeleton.vue';

export default {
    components: { SLoadingSkeleton },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },

        name: {
            type: String,
            required: false,
        },

        label: {
            type: String,
            required: false,
        },

        groups: {
            type: Object,
            required: false,
        },

        items: {
            type: [Object, Array],
            required: false,
        },

        placeholder: {
            type: String,
            required: false,
            default: 'Please select...',
        },

        withoutPlaceholder: {
            type: Boolean,
            default: false,
        },

        placeholderDisabled: {
            type: Boolean,
            default: false,
        },

        notFullWidth: {
            type: Boolean,
            default: false,
        },

        value: {
            type: String | Number,
            required: false,
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },

        hasError: {
            type: Boolean,
            default: false,
        },

        testId: {
            type: String,
            default: 'select',
        },
    },

    computed: {
        options() {
            if (Array.isArray(this.items)) {
                return this.items.reduce(
                    (array, value) => ({
                        ...array,
                        [value['value']]: value['text'],
                    }),
                    {}
                );
            }
            return this.items;
        },
    },

    methods: {
        isDefault() {
            return this.value === null || this.value === undefined || this.value === false;
        },
        isSelected(value) {
            return this.value === value;
        },
        onSelect(event) {
            this.$emit('input', event.target.value);
        },
    },
};
</script>
