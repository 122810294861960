function formatCurrency(value, decimalPlaces, hideSymbol = false) {
    if (!value && value !== 0) {
        return value;
    }

    const options = {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    };

    if (!hideSymbol) {
        options.style = 'currency';
        options.currency = 'GBP';
    }

    return new Intl.NumberFormat('en-GB', options).format(value);
}

export default formatCurrency;
