<template>
    <input
        :id="id"
        :name="name"
        :type="type"
        :value="value"
        :minlength="minlength"
        @input="onInput"
        :placeholder="placeholder"
        :required="required"
        ref="input"
        :class="invalid ? 'invalid' : ''"
    />
</template>
<script>
export default {
    props: {
        value: String,
        id: String,
        name: String,
        type: String,
        placeholder: String,
        required: Boolean,
        invalid: Boolean,
        minlength: String,
    },
    methods: {
        onInput(e) {
            this.$emit('on-input', e.target.value);
        },
        getRef() {
            return this.$refs.input;
        },
    },
};
</script>
