var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column login" }, [
    _c("h4", [_vm._v("First, what’s your email address?")]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "login-form d-flex flex-column",
        attrs: { novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "form-item d-flex flex-column" },
          [
            _c("label", { attrs: { for: "email" } }, [_vm._v("Email address")]),
            _vm._v(" "),
            _c("EmailField", {
              attrs: {
                value: _vm.email,
                id: "email",
                name: "email",
                type: "text",
                placeholder: "Email Address"
              },
              on: { "on-input": _vm.handleInput }
            }),
            _vm._v(" "),
            !!_vm.error
              ? _c("p", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex actions flex-column flex-md-row" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-dark",
              attrs: { disabled: _vm.loading },
              on: {
                click: function($event) {
                  return _vm.handleFormSubmit()
                }
              }
            },
            [_vm._v("Continue")]
          )
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "login-tag", attrs: { href: "/login" } }, [
          _vm._v("Log in")
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }