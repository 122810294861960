<template>
    <div class="logging-issue text-center">
        <Modal title="Can't log in?" text="Can't log in?" showOnlyAccept okTitle="Got it!">
            <div class="cant-login-modal">
                <h5>Are you a buyer, seller, tenant or landlord?</h5>
                <div class="content">
                    <p>
                        If you’re registered with an estate agent who uses Street, you may have received emails from us
                        asking you to log into your account. You’ll need to use that same email address to log in.
                    </p>

                    <p>
                        If you’ve never set or if you’ve forgotten password, you can
                        <a href="/forgot-password">reset it</a>.
                    </p>

                    <p>If you’re still struggling to log in, please contact your estate agent for help.</p>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script>
import Modal from './Modal';

export default {
    components: { Modal },
};
</script>
