const store = window.sessionStorage;

const get = () => {
    const item = store.getItem('flashMessages');

    return item ? JSON.parse(item) : [];
};

const push = (message, level = 'success') => {
    const messages = get('flashMessages');

    messages.push({ message, level });

    store.setItem('flashMessages', JSON.stringify(messages));
};

const forget = () => {
    store.removeItem('flashMessages');
};

const pop = () => {
    const flash = get();

    forget();

    return flash;
};

export default { push, get, forget, pop };
