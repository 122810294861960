import axios from 'axios';
import getEnv from '../utilities/env';

const siteProtocol = 'https';
const siteDomain = `${getEnv('STREET_DOMAIN')}`;
const apiUrl = `${siteDomain}/internal-api`;

const APP_URL = `${siteProtocol}://${siteDomain}`;
const ORIGIN = `${siteProtocol}://${siteDomain}`;
const API_URL = `${siteProtocol}://${apiUrl}`;
const CSRF_ELEMENT = window.document.head.querySelector('meta[name="csrf-token"]');

const CSRF = CSRF_ELEMENT ? CSRF_ELEMENT.getAttribute('content') || '' : '';

export const ORIGIN_HEADERS = {
    'X-CSRF-TOKEN': CSRF,
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': ORIGIN || '',
};

export const HEADERS = {
    ...ORIGIN_HEADERS,
    'Content-Type': 'application/json',
    Accept: 'application/json',
};

export function setupAxios() {
    // axios.defaults.baseURL = `/internal-api/`;
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = CSRF;
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = `${API_URL}`;
    axios.interceptors.response.use(undefined, error => {
        /**
         * Only intercept responses to the internal API
         */
        if (!error.response?.request.responseURL.startsWith(API_URL)) {
            return Promise.reject(error);
        }

        if (error.response.status === 401) {
            const reason = {
                code: error.code,
                error: error.message,
                responseURL: error.request.responseURL,
                responseText: error.request.responseText,
                responseXML: error.request.responseXML,
                statusText: error.request.statusText,
            };

            const base64Encoded = btoa(JSON.stringify(reason));

            window.location.href = `/logged-out?reason=${base64Encoded}`;

            return;
        }

        return Promise.reject(error);
    });

    window.axios = axios; // Some Blade / jQuery files still depend on this
}

export { APP_URL, ORIGIN, API_URL, CSRF_ELEMENT, CSRF };
