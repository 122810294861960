var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column login two-factor-auth" },
    [
      _c("h4", [_vm._v("Please enter a password for your account")]),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "login-form d-flex flex-column",
          attrs: { novalidate: "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-item d-flex flex-column" },
            [
              _c("label", { attrs: { for: "password-input" } }, [
                _vm._v("Enter password")
              ]),
              _vm._v(" "),
              _c("Input", {
                ref: "passwordInput",
                attrs: {
                  value: _vm.password,
                  id: "password-input",
                  name: "password-input",
                  type: "password",
                  placeholder: "",
                  disabled: _vm.loading,
                  invalid: _vm.errors.length > 0
                },
                on: { "on-input": _vm.handleInput }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-item d-flex flex-column" },
            [
              _c("label", { attrs: { for: "password-input2" } }, [
                _vm._v("Re-enter password")
              ]),
              _vm._v(" "),
              _c("Input", {
                ref: "confirmPasswordInput",
                attrs: {
                  value: _vm.passwordConfirmation,
                  id: "confirm-password-input",
                  name: "confirm-password-input",
                  type: "password",
                  placeholder: "",
                  disabled: _vm.loading,
                  invalid: _vm.errors.length > 0
                },
                on: { "on-input": _vm.handleConfirmInput }
              }),
              _vm._v(" "),
              _vm._l(_vm.errors, function(error, index) {
                return _vm.errors.length > 0
                  ? _c("span", [
                      _c("p", { staticClass: "error" }, [_vm._v(_vm._s(error))])
                    ])
                  : _vm._e()
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex actions flex-column flex-md-row" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-dark",
                attrs: { disabled: _vm.loading },
                on: {
                  click: function($event) {
                    return _vm.handleFormSubmit()
                  }
                }
              },
              [_vm._v("\n                Confirm Password\n            ")]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }