var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.enabledSocials.length > 0
    ? _c("div", { staticClass: "login-social d-flex flex-column" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "social-buttons" }, [
          _vm.enabledSocials.includes("google")
            ? _c(
                "a",
                {
                  staticClass: "btn btn-light",
                  attrs: {
                    href: _vm.route("login.social.redirect", {
                      provider: "google"
                    }),
                    type: "button"
                  }
                },
                [_c("GoogleIcon", { staticClass: "icon" })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.enabledSocials.includes("apple")
            ? _c(
                "a",
                {
                  staticClass: "btn btn-light",
                  attrs: {
                    href: _vm.route("login.social.redirect", {
                      provider: "apple"
                    }),
                    type: "button"
                  }
                },
                [_c("AppleIcon", { staticClass: "icon" })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.enabledSocials.includes("facebook")
            ? _c(
                "a",
                {
                  staticClass: "btn btn-light",
                  attrs: {
                    href: _vm.route("login.social.redirect", {
                      provider: "facebook"
                    }),
                    type: "button"
                  }
                },
                [_c("FacebookIcon", { staticClass: "icon" })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.enabledSocials.includes("microsoft")
            ? _c(
                "a",
                {
                  staticClass: "btn btn-light",
                  attrs: {
                    href: _vm.route("login.social.redirect", {
                      provider: "microsoft"
                    }),
                    type: "button"
                  }
                },
                [_c("WindowsIcon", { staticClass: "icon" })],
                1
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "d-md-none" }, [
          _vm._v("Unavailable for agents")
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("span", [_vm._v("Or login with")]),
        _vm._v(" "),
        _c("span", { staticClass: "d-none d-md-block" }, [
          _vm._v("Unavailable for agents")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }