<template>
    <div class="input-group password-container">
        <input
            :id="id"
            :name="name"
            :type="this.visible ? 'text' : 'password'"
            :value="value"
            :minlength="minlength"
            @input="onInput"
            :placeholder="placeholder"
            :required="required"
            ref="input"
            :class="invalid ? 'invalid' : ''"
        />
        <div :class="invalid ? 'invalid toggle' : 'toggle'">
            <div>
                <EyeOffIcon v-on:click="handleEyeClick(true)" v-if="!visible" class="icon" />
                <EyeOnIcon v-on:click="handleEyeClick(false)" v-if="visible" class="icon" />
            </div>
        </div>
    </div>
</template>
<script>
import EyeOffIcon from '../../assets/eye-off-icon.svg';
import EyeOnIcon from '../../assets/eye-on-icon.svg';
export default {
    props: {
        value: String,
        id: String,
        name: String,
        type: String,
        placeholder: String,
        required: Boolean,
        invalid: Boolean,
        minlength: String,
    },
    components: {
        EyeOffIcon,
        EyeOnIcon,
    },
    data() {
        return {
            visible: false,
        };
    },
    methods: {
        handleEyeClick(show) {
            this.visible = show;
        },
        onInput(e) {
            this.$emit('on-input', e.target.value);
        },
        getRef() {
            return this.$refs.input;
        },
    },
};
</script>
