<template>
    <div class="d-flex flex-column login two-factor-auth">
        <h4>Please enter a password for your account</h4>

        <form class="login-form d-flex flex-column" novalidate @submit.prevent>
            <div class="form-item d-flex flex-column">
                <label for="password-input">Enter password</label>
                <Input
                    :value="password"
                    id="password-input"
                    name="password-input"
                    type="password"
                    placeholder=""
                    ref="passwordInput"
                    :disabled="loading"
                    @on-input="handleInput"
                    :invalid="errors.length > 0"
                />
            </div>
            <div class="form-item d-flex flex-column">
                <label for="password-input2">Re-enter password</label>
                <Input
                    :value="passwordConfirmation"
                    id="confirm-password-input"
                    name="confirm-password-input"
                    type="password"
                    placeholder=""
                    ref="confirmPasswordInput"
                    :disabled="loading"
                    @on-input="handleConfirmInput"
                    :invalid="errors.length > 0"
                />

                <span v-if="errors.length > 0" v-for="(error, index) in errors">
                    <p class="error">{{ error }}</p>
                </span>
            </div>

            <div class="d-flex actions flex-column flex-md-row">
                <button v-on:click="handleFormSubmit()" :disabled="loading" class="btn btn-dark">
                    Confirm Password
                </button>
            </div>
        </form>
    </div>
</template>
<script>
import axios from 'axios';
import PasswordForm from '../organisms/reset/ResetPasswordForm.vue';
import Input from '../atoms/PrivateInput.vue';
import Select from '../../../components/inputs/Select.vue';

export default {
    components: { Select, Input, PasswordForm },
    props: {
        user: Object,
        signedUrl: String,
    },
    data() {
        return {
            password: '',
            passwordConfirmation: '',
            errors: [],
            valid: false,
            loading: false,
        };
    },
    methods: {
        async handleFormSubmit() {
            this.errors = [];

            this.loading = true;

            try {
                const response = await axios.post(
                    this.signedUrl,
                    {
                        password: this.password,
                        password_confirmation: this.passwordConfirmation,
                    },
                    { withCredentials: true }
                );

                window.location.href = '/login?setPassword=true';
            } catch (e) {
                this.loading = false;
                if (e.response?.status === 422) {
                    this.errors = e.response.data?.errors?.password;
                } else {
                    throw e;
                }
            }
        },
        handleInput(value) {
            this.password = value;
            this.errors = [];
        },
        handleConfirmInput(value) {
            this.passwordConfirmation = value;
            this.errors = [];
        },
    },
};
</script>
