var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column login" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "login-form d-flex flex-column",
        attrs: { autocomplete: "off", novalidate: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.handleFormSubmit($event)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "form-item d-flex flex-column" },
          [
            _c("label", { attrs: { for: "password-input" } }, [
              _vm._v("Enter password")
            ]),
            _vm._v(" "),
            _c("Input", {
              ref: "passwordInput",
              attrs: {
                value: _vm.password,
                id: "password-input",
                name: "password-input",
                type: "password",
                placeholder: "",
                disabled: _vm.loading,
                invalid: !!_vm.error
              },
              on: { "on-input": _vm.handleInput }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-item d-flex flex-column" },
          [
            _c("label", { attrs: { for: "password-input2" } }, [
              _vm._v("Re-enter password")
            ]),
            _vm._v(" "),
            _c("Input", {
              ref: "passwordInput2",
              attrs: {
                value: _vm.passwordConfirmation,
                id: "password-input2",
                name: "password-input2",
                type: "password",
                placeholder: "",
                disabled: _vm.loading,
                invalid: !!_vm.error
              },
              on: { "on-input": _vm.handleInput2 }
            }),
            _vm._v(" "),
            !!_vm.error
              ? _c("p", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex actions flex-column flex-md-row" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-dark",
              attrs: { type: "submit", disabled: _vm.loading }
            },
            [_vm._v("Continue")]
          )
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "login-tag", attrs: { href: "/login" } }, [
          _vm._v("Log in")
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-description" }, [
      _c("h4", [_vm._v("Great, time to set your new password")]),
      _vm._v(" "),
      _c("p", [_vm._v("It’ll need to be at least 8 characters")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }