<template>
    <div class="alert-message">
        <span><ThumbIcon class="icon" /></span>
        <span>{{ message }}</span>
    </div>
</template>
<script>
import ThumbIcon from '../../assets/thumb-icon.svg';
export default {
    components: {
        ThumbIcon,
    },
    props: {
        message: String,
    },
};
</script>
