var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    ref: "input",
    class: _vm.invalid ? "invalid" : "",
    attrs: {
      id: _vm.id,
      name: _vm.name,
      type: _vm.type,
      minlength: _vm.minlength,
      placeholder: _vm.placeholder,
      required: _vm.required
    },
    domProps: { value: _vm.value },
    on: { input: _vm.onInput }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }