// eslint-disable-next-line
__webpack_public_path__ = (window.envs?.STREET_ASSET_URL ?? '') + '/';

import Vue from 'vue';
import ZiggyVue from './vue/mixins/ZiggyMixin';
import { Ziggy } from './routes';
import { setupAxios } from './constants/requests';

setupAxios();

// Bootstrap
import { ModalPlugin } from 'bootstrap-vue';
Vue.use(ModalPlugin);

// Ziggy set up
if (Ziggy && typeof Ziggy === 'object') {
    Ziggy.url = window.location.origin;
}

Vue.use(ZiggyVue, Ziggy || undefined);

// Global Mixins
import Mixins from './vue/global-mixins';
Vue.mixin(Mixins);

// Components
import LoginFormTemplate from './vue/guest/components/templates/LoginFormTemplate';
import LinkFormTemplate from './vue/guest/components/templates/LinkFormTemplate';
import SetPasswordTemplate from './vue/guest/components/templates/SetPasswordTemplate';
import ResetPasswordFormFormTemplate from './vue/guest/components/templates/ResetPasswordFormTemplate';
Vue.component('login-form', LoginFormTemplate);
Vue.component('set-password', SetPasswordTemplate);
Vue.component('link-form', LinkFormTemplate);
Vue.component('reset-password-form', ResetPasswordFormFormTemplate);

new Vue({
    el: '#content',
});
