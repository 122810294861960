import { render, staticRenderFns } from "./LinkFormTemplate.vue?vue&type=template&id=da473778&"
import script from "./LinkFormTemplate.vue?vue&type=script&lang=js&"
export * from "./LinkFormTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('da473778')) {
      api.createRecord('da473778', component.options)
    } else {
      api.reload('da473778', component.options)
    }
    module.hot.accept("./LinkFormTemplate.vue?vue&type=template&id=da473778&", function () {
      api.rerender('da473778', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/src/js/vue/guest/components/templates/LinkFormTemplate.vue"
export default component.exports