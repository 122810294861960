import route from '../../../../../vendor/tightenco/ziggy/dist/index';

export default {
    install: (v, options) =>
        v.mixin({
            methods: {
                route: (name, params, absolute = false, config = options) => route(name, params, absolute, config),
            },
        }),
};
