<template>
    <div>
        <a v-b-modal.modal-vue class="text-decoration-underline">{{ text }}</a>

        <b-modal id="modal-vue" :ok-only="showOnlyAccept" :ok-title="okTitle">
            <div slot="modal-header">
                <h4>{{ title }}</h4>
            </div>
            <slot></slot>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        title: String,
        okTitle: String,
        text: String,
        showOnlyAccept: Boolean,
    },
};
</script>
