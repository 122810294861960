<template>
    <div class="social-auth-form-container d-flex flex-column">
        <EmailForm
            v-if="state === 'email'"
            :showPasswordless="showPasswordless"
            @on-email-continue="handleEmailContinue"
        />
        <PasswordForm
            v-if="state === 'password'"
            :email="email"
            :name="name"
            @on-another-account="handleAnotherAccount"
            @on-two-factor-auth-enabled="handleTwoFactorAuthEnabled"
        />
        <TwoFactorAuthenticationForm
            v-if="state === '2fa'"
            :email="email"
            :password="password"
            :name="name"
            @on-show-recover-code-form="handleShowRecoverCodeForm"
        />

        <TwoFactorAuthenticationRecoveryForm
            v-if="state === '2fa-recovery'"
            :email="email"
            :password="password"
            :name="name"
            @on-two-factor-auth-enabled="handleTwoFactorAuthEnabled"
        />

        <SocialLogin v-if="showSocials" :enabled-socials="enabledSocials" />
        <CantLogin v-if="showCantLoginModal" />
    </div>
</template>
<script>
import SocialLogin from '../organisms/shared/SocialLogin';
import EmailForm from '../organisms/login/LoginEmailForm';
import PasswordForm from '../organisms/login/LoginPasswordForm';
import TwoFactorAuthenticationForm from '../organisms/login/TwoFactorAuthenticationForm';
import TwoFactorAuthenticationRecoveryForm from '../organisms/login/TwoFactorAuthenticationRecoveryForm';
import CantLogin from '../molecules/CantLogin';

export default {
    components: {
        SocialLogin,
        EmailForm,
        PasswordForm,
        TwoFactorAuthenticationForm,
        TwoFactorAuthenticationRecoveryForm,
        CantLogin,
    },
    props: {
        passwordless: String,
        setPassword: Boolean,
        enabledSocialLogins: String,
        userDetails: String,
    },
    created() {
        const { email, name } = JSON.parse(this.userDetails);
        if (email) {
            this.state = 'password';
            this.email = email;
            this.name = name;
        }
    },
    data() {
        return {
            error: null,
            loading: false,
            state: 'email',
            email: '',
            password: '',
            name: '',
            showModal: false,
            showPasswordless: this.passwordless === '1',
            showSetPassword: this.setPassword === '1',
            showSocials: true,
            showCantLoginModal: true,
            enabledSocials: JSON.parse(this.enabledSocialLogins),
        };
    },
    methods: {
        handleEmailContinue(value) {
            this.email = value;
            this.state = 'password';
        },
        handleTwoFactorAuthEnabled(email, password) {
            this.showSocials = false;
            this.showCantLoginModal = false;
            this.email = email;
            this.password = password;
            this.state = '2fa';
        },
        handleShowRecoverCodeForm() {
            this.showSocials = false;
            this.showCantLoginModal = false;

            this.state = '2fa-recovery';
        },
        handleAnotherAccount() {
            this.email = '';
            this.name = '';
            this.state = 'email';
        },
    },
};
</script>
