<template>
    <div class="social-auth-form-container d-flex flex-column">
        <EmailForm
            v-if="state === 'email'"
            :provider="provider"
            :identifier="identifier"
            @on-email-continue="handleEmailContinue"
        />
        <CodeForm
            v-if="state === 'code'"
            :provider="provider"
            :social-id="socialId"
            :email="email"
            @on-another-account="handleAnotherAccount"
        />
        <CantLogin />
    </div>
</template>
<script>
import EmailForm from '../organisms/link/EmailForm.vue';
import CodeForm from '../organisms/link/CodeForm.vue';
import CantLogin from '../molecules/CantLogin.vue';

export default {
    components: {
        CantLogin,
        EmailForm,
        CodeForm,
    },
    props: {
        provider: String,
        identifier: String,
        socialId: String,
    },
    data() {
        return {
            error: null,
            loading: false,
            state: 'email',
            email: '',
        };
    },
    methods: {
        handleEmailContinue(value) {
            this.email = value;
            this.state = 'code';
        },
        handleAnotherAccount() {
            this.email = '';
            this.state = 'email';
        },
    },
};
</script>
