<template>
    <div class="d-flex flex-column login two-factor-auth">
        <h4>Log in to continue</h4>

        <p>
            <strong>Please enter your recovery code, given to you when you set up Two Factor Authentication.</strong>
            <small class="form-text text-muted">
                If you don't have your Recovery Code, please speak with your Network administrator.
            </small>
        </p>

        <form class="login-form d-flex flex-column" novalidate @submit.prevent>
            <div class="form-item d-flex flex-column">
                <label for="email">Code</label>
                <input
                    v-model="recovery_code"
                    id="recovery_code"
                    name="recovery_code"
                    type="text"
                    placeholder="Enter your recovery code"
                />
                <p v-if="!!error" class="error">{{ error }}</p>
            </div>

            <div class="d-flex actions flex-column flex-md-row">
                <button v-on:click="handleFormSubmit()" :disabled="loading" class="btn btn-dark">Sign In</button>
            </div>
        </form>

        <p class="recovery">
            Having trouble getting the code?
            <span v-on:click="handleTwoFactorAuthEnabled()">Log in using authentication code</span>
        </p>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    props: {
        email: String,
        password: String,
    },
    data() {
        return {
            recovery_code: '',
            error: '',
            valid: false,
            loading: false,
        };
    },
    methods: {
        async handleFormSubmit() {
            this.error = null;

            this.loading = true;

            try {
                const response = await axios.post(
                    this.route('internal-api.auth.2fa.login'),
                    {
                        recovery_code: this.recovery_code,
                        email: this.email,
                        password: this.password,
                    },
                    { withCredentials: true }
                );

                window.location.href = response.data?.data?.attributes.redirect_url;
            } catch (e) {
                if (e.response?.status === 422) {
                    this.loading = false;
                    this.error = 'Invalid code, please check your details and try again.';
                } else {
                    throw e;
                }
            }
        },

        handleTwoFactorAuthEnabled() {
            this.$emit('on-two-factor-auth-enabled', this.email, this.password);
        },
    },
};
</script>
