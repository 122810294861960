<template>
    <div v-if="enabledSocials.length > 0" class="login-social d-flex flex-column">
        <div class="d-flex justify-content-between align-items-center">
            <span>Or login with</span>
            <span class="d-none d-md-block">Unavailable for agents</span>
        </div>

        <div class="social-buttons">
            <a
                v-if="enabledSocials.includes('google')"
                :href="route('login.social.redirect', { provider: 'google' })"
                type="button"
                class="btn btn-light"
            >
                <GoogleIcon class="icon" />
            </a>
            <a
                v-if="enabledSocials.includes('apple')"
                :href="route('login.social.redirect', { provider: 'apple' })"
                type="button"
                class="btn btn-light"
            >
                <AppleIcon class="icon" />
            </a>
            <a
                v-if="enabledSocials.includes('facebook')"
                :href="route('login.social.redirect', { provider: 'facebook' })"
                type="button"
                class="btn btn-light"
            >
                <FacebookIcon class="icon" />
            </a>
            <a
                v-if="enabledSocials.includes('microsoft')"
                :href="route('login.social.redirect', { provider: 'microsoft' })"
                type="button"
                class="btn btn-light"
            >
                <WindowsIcon class="icon" />
            </a>
        </div>
        <span class="d-md-none">Unavailable for agents</span>
    </div>
</template>
<script>
import GoogleIcon from '../../../assets/google-icon.svg';
import AppleIcon from '../../../assets/apple-icon.svg';
import FacebookIcon from '../../../assets/facebook-icon.svg';
import WindowsIcon from '../../../assets/windows-icon.svg';
export default {
    components: {
        GoogleIcon,
        AppleIcon,
        FacebookIcon,
        WindowsIcon,
    },
    props: {
        enabledSocials: Array,
    },
};
</script>
