var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column login" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.showCodeSentMessage
        ? _c("alert-message", { attrs: { message: "Your code has been sent" } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "login-form d-flex flex-column",
          attrs: { autocomplete: "off", novalidate: "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleFormSubmit($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-item d-flex flex-column" },
            [
              _c("label", { attrs: { for: "email-input" } }, [_vm._v("Code")]),
              _vm._v(" "),
              _c("CodeField", {
                ref: "codeInput",
                attrs: {
                  value: _vm.code,
                  id: "code-input",
                  name: "code-input",
                  type: "text",
                  placeholder: "e.g. 123456"
                },
                on: { "on-input": _vm.handleInput }
              }),
              _vm._v(" "),
              !!_vm.error
                ? _c("p", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex actions flex-row" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-light",
                attrs: { type: "button", disabled: _vm.loading },
                on: {
                  click: function($event) {
                    return _vm.emailLoginCode()
                  }
                }
              },
              [_vm._v("\n                Resend code\n            ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-dark",
                attrs: { type: "submit", disabled: _vm.loading }
              },
              [_vm._v("Continue")]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "back login-tag",
              on: {
                click: function($event) {
                  return _vm.goBack()
                }
              }
            },
            [_vm._v("Go back")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-description" }, [
      _c("h4", [_vm._v("Enter your verification code")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n            If you have a Street account, we’ve sent you an email with a code - enter it below to verify that it’s\n            you\n        "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }