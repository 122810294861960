var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "social-auth-form-container d-flex flex-column" },
    [
      _vm.state === "email"
        ? _c("EmailForm", {
            on: { "on-email-continue": _vm.handleEmailContinue }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.state === "code"
        ? _c("CodeForm", {
            attrs: { email: _vm.email },
            on: {
              "on-code-continue": _vm.handleCodeContinue,
              "on-code-back": _vm.handleCodeBack
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.state === "password"
        ? _c("PasswordForm", { attrs: { email: _vm.email, code: _vm.code } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }