<template>
    <div class="d-flex flex-column login">
        <div class="title-description">
            <h4>Enter your verification code</h4>
            <p>
                If you have a Street account, we’ve sent you an email with a code - enter it below to verify that it’s
                you
            </p>
        </div>

        <alert-message v-if="showCodeSentMessage" message="Your code has been sent"></alert-message>

        <form @submit.prevent="handleFormSubmit" autocomplete="off" novalidate class="login-form d-flex flex-column">
            <div class="form-item d-flex flex-column">
                <label for="email-input">Code</label>
                <CodeField
                    :value="code"
                    id="code-input"
                    name="code-input"
                    type="text"
                    placeholder="e.g. 123456"
                    ref="codeInput"
                    @on-input="handleInput"
                />
                <p v-if="!!error" class="error">{{ error }}</p>
            </div>
            <div class="d-flex actions flex-row">
                <button v-on:click="emailLoginCode()" type="button" :disabled="loading" class="btn btn-light">
                    Resend code
                </button>
                <button type="submit" :disabled="loading" class="btn btn-dark">Continue</button>
            </div>
            <div v-on:click="goBack()" class="back login-tag">Go back</div>
        </form>
    </div>
</template>
<script>
import CodeField from '../../atoms/Input.vue';
import AlertMessage from '../../molecules/AlertMessage';
import axios from 'axios';
export default {
    components: {
        CodeField,
        AlertMessage,
    },
    props: {
        email: String,
    },
    data() {
        return {
            code: '',
            error: '',
            valid: false,
            loading: false,
            showCodeSentMessage: false,
        };
    },
    methods: {
        handleInput(value) {
            this.error = '';
            this.code = value;
            this.showCodeSentMessage = false;
            this.valid = value.length;
        },
        async handleFormSubmit() {
            this.error = null;
            this.showCodeSentMessage = false;

            if (!this.valid) {
                this.error = 'Please enter your code.';
                return;
            }

            this.loading = true;
            const response = await axios
                .post(this.route('internal-api.auth.check-reset-code'), {
                    email: this.email,
                    code: this.code,
                })
                .catch(() => null);
            if (response) {
                this.$emit('on-code-continue', this.code);
            } else {
                this.error = 'Sorry, that code doesn’t match. Please try again or resend the code.';
            }
            this.loading = false;
        },
        async goBack() {
            this.$emit('on-code-back');
        },
        async emailLoginCode() {
            this.error = null;
            this.loading = true;
            const response = await axios
                .post(this.route('internal-api.auth.send-reset-code'), {
                    email: this.email,
                })
                .catch(() => null);
            if (response) {
                this.showCodeSentMessage = true;
            } else {
                this.error = 'We are currently facing difficulties with this request please try again later.';
            }
            this.loading = false;
        },
    },
};
</script>
